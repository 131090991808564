<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item class="ui-form__item" name="userInfo" label="用户信息">
						<a-input v-model:value="formState.userInfo" placeholder="请输入活动名称"></a-input>
					</a-form-item>
					
					<a-form-item class="ui-form__item" label="反馈时间">
						<a-range-picker v-model:value="time"></a-range-picker>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
					</a-col>
					<a-col :span="6" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<cTable ref="cTable" isPage :searchData="searchData" isRequest :requestFun="getFeedbackList" :columns="columns">
					<template #bodyCell="{ column, record,index }">
						<template v-if="column.key === 'userInfo'">
							<div>
								<div>用户昵称：{{ record.userInfo.nickname || '-' }}</div>
								<div>用户手机号：{{ record.userInfo.phone || '-' }}</div>
							</div>
						</template>
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.createTime) }}
						</template>
					</template>
				</cTable>
			</div>
		</a-spin>

	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import cTable from '@/components/cTable/index.vue';
	import { getFeedbackList } from "@/service/modules/comment.js";
	export default {
		components: {
			Icon,
			cTable
		},
		data() {
			return {
				loading: false,
				searchData: {},
				time: [],
				formState: {},
				columns: [{
					title: '用户信息',
					key: 'userInfo',
				}, {
					title: '填写手机号',
					dataIndex: 'phone',
				}, {
					title: '填写邮箱',
					dataIndex: 'email',
				}, {
					title: '反馈内容',
					dataIndex: 'content',
				}, {
					title: '反馈时间',
					key: 'createTime',
				}],
			}
		},
		mounted() {
			this.onSearch();
		},
		methods: {
			getFeedbackList: getFeedbackList,
			reset() {
				this.time = [];
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				if (this.time && this.time.length) {
					this.searchData.startCreateTime = this.time[0].startOf('days').unix();
					this.searchData.endCreateTime = this.time[1].endOf('days').unix();
				}
				this.getData(true);
			},
			async getData(isRef) {
				if (!isRef) {
					this.$refs.cTable.toQurey();
				} else {
					this.$refs.cTable.refQuery();
				}
			},
			onDisabled(item) {
				this.$confirm({
					title: '提示',
					content: '确认' + (item.isDisabled ? '启用' : '禁用') + '该活动吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await updatePlatformActivity({
								id: item.id,
								isDisabled: item.isDisabled ? 0 : 1
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success((item.isDisabled ? '启用' : '禁用') + '成功');
								this.getData();
							}
						} catch (e) {
							this.loading = false;
						}
					}
				})
			},
			onAdd() {
				this.showModal = true;
				this.isEdit = false;
				this.id = 0;
			},
			onEdit(item) {
				this.showModal = true;
				this.isEdit = true;
				this.id = item.id;
			},
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData();
					})
				}
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确认删除该活动吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await deletePlatformActivity({
								id: item.id
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功');
								this.getData();
							}
						} catch (e) {
							this.loading = false;
						}
					}
				})
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>