import {send} from "../index";

export function getCommentList(data, opts = {}) {
	//  评价列表
  return send({
    url: "/admin/userComment/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deleteComment(data, opts = {}) {
	//  删除评价
  return send({
    url: "/admin/userComment/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCommentTotalList(data, opts = {}) {
	//  评价汇总
  return send({
    url: "/admin/userComment/totalList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCommentAuditList(data, opts = {}) {
	//  评价审核列表
  return send({
    url: "/admin/userCommentAudit/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function commentAudit(data, opts = {}) {
	//  审核
  return send({
    url: "/admin/userCommentAudit/audit.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function commentBatchAudit(data, opts = {}) {
	//  批量审核
  return send({
    url: "/admin/userCommentAudit/auditBatch.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function changeCommentStatus(data, opts = {}) {
	//  修改评论属性
  return send({
    url: "/admin/userComment/switch.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getReplyList(data, opts = {}) {
	//  获取评价下的评论
  return send({
    url: "/admin/userComment/getReplyList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deleteReply(data, opts = {}) {
	//  删除评价下的评论
  return send({
    url: "/admin/userCommentReply/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getFeedbackList(data, opts = {}) {
	//  反馈列表
  return send({
    url: "/admin/userFeedback/list.do",
    method: "POST",
    data,
    ...opts,
  });
}